<template>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    title="批量修改"
    v-model="dialogFormVisible"
    width="700px"
  >
    <div class="main">
      <div class="each" style="width: 60%">
        <p>
          （1）点击导出科目余额表
          <el-button type="primary" size="small" @click="daochu()"
            >导出</el-button
          >
        </p>
        <p>（2）自主修改excel字段信息并保存</p>
        <p>（3）导入修改后的excel，批量修改</p>
        <p style="text-indent: 2em; display: flex">
          <el-input
            style="width: 250px; margin-right: 10px"
            v-model="path"
            size="small"
          ></el-input>
          <input
            size="small"
            ref="excel-upload-input"
            class="excel-upload-input"
            style="display: none"
            type="file"
            accept=".xlsx, .xls, .xml"
            @change="handleClick"
          />
          <el-button type="primary" @click="handleUpload()" size="small"
            >请选择文件</el-button
          >
        </p>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="uploadExcel()"
          :loading="daoruLoading"
          >上传修改</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { subjectBalanceInit } from "@/api/subject";
import { exportQiChu } from "@/api/export";
export default {
  name: "initEdit",
  props: {
    comId: { type: Number, default: 0 },
    period: { type: String, default: "" },
  },
  data() {
    return {
      dialogFormVisible: false,
      path: "",
      rawFile: "",
    };
  },
  methods: {
    init() {
      this.dialogFormVisible = true;
      this.path = "";
      this.rawFile = ""
    },
    //导出
    daochu() {
      let param = {
        comId: this.comId,
        type: 1,
      };
      exportQiChu(param).then((res) => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
    //导入
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      let path = e.target.value; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile;
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    // isFileChanged(file){
    //   return new Promise((resolve, reject) => {
    //     file.slice(0, 1).arrayBuffer()
    //         .then(() => resolve(false))
    //         .catch(() => reject(true))
    //   })
    // },
    async uploadExcel() {
      this.daoruLoading = true;
      // let isChange
      // try {
      //   await this.isFileChanged(this.rawFile)
      // } catch (error) {
      //   isChange = error
      // }

      // if (isChange) {
      //   this.$message.error('文件可能被修改，请重新选择文件')
      //   this.$refs['excel-upload-input'].value = null
      //   this.daoruLoading = false;
      //   this.path = ''
      //   this.rawFile = ''
      //   return
      // }
      
      const param = new FormData(); // 创建form对象
      param.append("file", this.rawFile, this.rawFile.name);
      param.append("comId", this.comId);
      param.append("period", this.period);
      subjectBalanceInit(param).then((res) => {
        this.daoruLoading = false;
        if (res.data.msg == "success") {
          this.$qzfMessage("修改成功");
          this.dialogFormVisible = false;
          this.$emit("success");
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.common_dialog_box {
  width: 100%;
  margin: 0 auto;
}
.common_dialog_box .common_title {
  font-size: 14px;
  color: black;
  font-weight: bold;
  line-height: 36px;
  //border-bottom: 1px solid #c2c2c2;
  margin-bottom: 10px;
}
.common_dialog_box .common_title span {
  color: red;
  margin-left: 30px;
  font-weight: normal !important;
}
.common_dialog_box .item {
  // width: 46%;
  // margin-bottom:20px
}
.common_dialog_box .item:nth-child(2n + 1) {
  margin-left: 0;
}
.new_dialog .el-button--primary {
  background: var(--themeColor, #17a2b8);
  border-color: var(--themeColor, #17a2b8);
}
.common_dialog_box .el-select {
  margin: 0 0 0 0 !important;
  width: 100%;
}
.common_dialog_box {
  :deep(.el-input__wrapper) {
    width: 200px;
  }
}
.main {
  display: flex;
  p {
    margin-bottom: 10px;
  }
}
</style>
