<template>
  <div style="position: relative">
    <div class="hint">
      <i class="iconfont icon-gantanhao1"></i>温馨提示：每晚系统自动更新本期数据，如需更新之前账期或者实时数据请手动进行数据更新
    </div>
    <el-tabs type="border-card">
      <el-tab-pane label="财务统计表">
        <div class="content_data">
          <div class="content_data_size">
            <div style="width: 8%">统计</div>
            <div class="each">
              <div class="icon_bg">
                <i class="iconfont icon-gongsi1"></i>
              </div>
              <div style="margin-left: 5px; cursor: pointer" @click="getType(0)">
                <div style="margin-bottom: 5px">企业总数</div>
                <a>
                  <span>{{ comTotal }}</span>家
                </a>
              </div>
            </div>
            <div class="each">
              <div class="icon_bg">
                <i class="iconfont icon-zichan"></i>
              </div>
              <div style="margin-left: 5px; cursor: pointer" @click="getType(1)">
                <div style="margin-bottom: 5px">有收入</div>
                <a>
                  <span>{{ comYsr }}</span>家
                </a>
              </div>
            </div>
            <div class="each">
              <div class="icon_bg">
                <i class="iconfont icon-jiezhang"></i>
              </div>
              <div style="margin-left: 5px; cursor: pointer" @click="getType(2)">
                <div style="margin-bottom: 5px">有盈利</div>
                <a>
                  <span>{{ comYyl }}</span>家
                </a>
              </div>
            </div>
            <div class="each">
              <div class="icon_bg">
                <i class="iconfont icon-jizhanghesuan"></i>
              </div>
              <div style="margin-left: 5px; cursor: pointer" @click="getType(3)">
                <div style="margin-bottom: 5px">有亏损</div>
                <a>
                  <span>{{ comYks }}</span>家
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="content_select">
          <div style="display: flex;align-items: center;">
            <qzf-search-period v-model:period="listQuery.period" @success="getList"
              style="width:85px"></qzf-search-period>
            <el-input placeholder="请输入企业名称/编号" v-model="listQuery.name" style="width: 140px; margin: 0 5px" size="small"
              @keyup.enter="getList" clearable /><el-button type="primary" @click="getList" size="small"
              style="margin-right: 5px">搜索</el-button>
            <search @success="getList" @cancel="cancel">
              <el-form style="width: 400px" label-width="110px" :inline="true" class="styleForm" size="small">
                <el-form-item label="纳税人类型：">
                  <selecttaxtype v-model:type="listQuery.nsrType" style="width: 215px; margin-left: 0"></selecttaxtype>
                </el-form-item>
                <el-form-item label="税局：">
                  <selectcity v-model:citys="listQuery.districtCode" style="width: 215px; margin-left: 0"></selectcity>
                </el-form-item>
                <el-form-item label="选择人员：">
                  <selectuser code="bookkeeping" v-model:userId="listQuery.userIds"
                    style="width: 215px; margin-left: 0"></selectuser>
                </el-form-item>
                <el-form-item label="公司类型：">
                  <el-select v-model="listQuery.companyType" size="small" placeholder="请选择公司类型" filterable clearable
                    style="width: 215px; margin-left: 0">
                    <el-option v-for="item in typeOptions" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="账期：">
                  <qzf-search-period v-model:period="listQuery.beginPeriod" style="width: 100px"></qzf-search-period>
                  <span style="margin: 0 3px">至</span>
                  <qzf-search-period v-model:period="listQuery.endPeriod" style="width: 100px"></qzf-search-period>
                </el-form-item>
                <el-form-item label="结账状态：">
                  <el-radio-group v-model="listQuery.settleStatus">
                    <el-radio :label="0">全部</el-radio>
                    <el-radio :label="1">已结账</el-radio>
                    <el-radio :label="2">未结账</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </search>
            <span style="margin:0 5px"><el-switch v-model="listQuery.statType" active-text="只显示有数据项" inactive-text=""
                @change="getList" size="small"></el-switch></span>

          </div>
          <div>
            <el-button :disabled="!$buttonStatus('cwtj_gxsj') || listQuery.limit > 20" type="success" size="small"
              @click="reFresh" style="margin-left: 10px" icon="Refresh">查看最新数据</el-button>
            <el-button @click="daochu()" type="primary" size="small" :disabled="!$buttonStatus('cwtj_dc')">
              <el-icon>
                <FolderOpened />
              </el-icon> <span>导出</span>
            </el-button>
            <el-button @click="printAll()" type="success" size="small" :disabled="!$buttonStatus('cwtj_dy')">
              <el-icon>
                <Printer />
              </el-icon> <span>打印</span>
            </el-button>
          </div>
        </div>
        <div>
          <el-table :data="list" border style="width: 100%" v-loading="loading" :height="contentStyleObj"
            @select="handleSelectionChange" stripe @sort-change="sortChange" ref="tableScroll" id="tableLazyLoad"
            @select-all="handleSelectionChangeAll">
            <template #empty>
              <el-empty :image-size="150" description="没有数据"></el-empty>
            </template>
            <el-table-column align="center" prop="id" type="selection" width="55" />
            <el-table-column label="编号" align="center" width="80" fixed="left" prop="sort" sortable="custom">
              <template #default="scope">
                <TableSortCell :row="scope.row" />
              </template>
            </el-table-column>
            <el-table-column prop="name" label="企业名称" min-width="150" show-overflow-tooltip fixed>
              <template #default="scope">
                <TagNameCopy :row="scope.row" :showAuthIcon="false" />
              </template>
            </el-table-column>
            <el-table-column prop="districtName" label="税局" width="65" align="center">
              <template #default="scope">
                <span>{{ $cityFilter(scope.row.district) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="startAccountPeriod" label="最后结账月" align="right" width="90" />
            <el-table-column prop="count" label="凭证数" align="right" width="100" sortable />
            <el-table-column prop="yysr" label="营业收入(本期)" sortable align="right" width="130" />
            <el-table-column prop="yycb" label="营业成本(本期)" sortable align="right" width="130" />
            <el-table-column prop="qjfy" label="期间费用(本期)" sortable align="right" width="130" />
            <el-table-column prop="lrze" label="利润总额" sortable align="right" />
            <el-table-column prop="ytfy" label="预提费用" sortable align="right" />
            <el-table-column prop="wlzg" label="往来暂估" sortable align="right" />
            <el-table-column prop="wfplr" label="未分配利润" sortable align="right" />
            <el-table-column prop="tjTime" label="更新时间" align="center" width="150">
              <template #default="scope">
                <span v-if="!(scope.row.tjTime).match('0001')">
                  {{
                    $parseTime(scope.row.tjTime, "{y}-{m}-{d} {h}:{i}:{s}")
                  }}
                </span>
                <span v-else></span>
              </template>
            </el-table-column>
            <el-table-column label="会计" align="center" width="60">
              <template #default="scope">
                <DynamicPopover>
                  <template #reference>
                    <div @click="poper(scope.row)" style="cursor: pointer">
                      <i class="iconfont icon-huiji"></i>
                    </div>
                  </template>
                  <KjTable :list="gridData" />
                </DynamicPopover>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="120">
              <template #default="scope">
                <el-button @click="refreshOne(scope.row)" size="small" type="primary" icon="Refresh"
                  plain>数据更新</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <qzf-pagination v-show="total > 0" :total="total" v-model:page="listQuery.page"
            v-model:limit="listQuery.limit" @pagination="getList()" type="cwtj" />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { currentAccountPeriod } from "@/utils";
import {
  cwtjList,
  cwtjFresh,
  cwtjExport,
  cwtjPrint,
  companyUserList,
  cwtjs
} from "@/api/company";
import selectcity from "@/components/Screening/selectcity";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import DynamicPopover from "@/components/DynamicPopover/DynamicPopover";
import KjTable from "@/components/table-cell-render/KjTable";
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import { COMPANY_TYPE_OPTION } from '@/utils/commonData.js'

export default {
  name: "financial-total",
  components: {
    selectcity,
    selecttaxtype,
    DynamicPopover,
    KjTable,
    TagNameCopy,
    TableSortCell,
  },
  props: {},
  data() {
    return {
      typeOptions: COMPANY_TYPE_OPTION,
      listQuery: {
        page: 1,
        limit: 20,
        name: "",
        beginPeriod: "",
        endPeriod: "",
        period: currentAccountPeriod(),
        type: 0,
        companyType: ''
      },
      loading: false,
      list: [],
      total: 0,
      comTotal: 0,
      comYks: 0,
      comYsr: 0,
      comYyl: 0,
      ids: [],
      selects: [],
      totalPage: 0,
      pageSize: 50,
      tableData: [],
      currentPage: 1,
      allChecked: false,
      gridData: [],
    };
  },
  created() {
    this.listQuery.limit = localStorage.getItem("cwtj")
      ? localStorage.getItem("cwtj") * 1
      : 20;
    this.contentStyleObj = this.$getHeight(326);
  },
  mounted() {
    this.getList();
  },
  methods: {
    cancel() {
      let originLimit = this.listQuery.limit;
      this.listQuery = {
        page: 1,
        limit: originLimit,
        name: "",
        beginPeriod: "",
        endPeriod: "",
        period: currentAccountPeriod(),
        type: 0,
        companyType: ''
      };
      this.getList();
    },
    getList() {
      if (
        (this.listQuery.beginPeriod && !this.listQuery.endPeriod) ||
        (!this.listQuery.beginPeriod && this.listQuery.endPeriod)
      ) {
        this.$qzfMessage("请选择完整的时间段", 1);
        return;
      }
      this.loading = true;
      cwtjList(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.comTotal = res.data.data.comTotal;
          this.comYks = res.data.data.comYks;
          this.comYsr = res.data.data.comYsr;
          this.comYyl = res.data.data.comYyl;
          this.tableData = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
          this.setScroll();
        }
      });
    },
    setScroll() {
      //先重置状态和数组
      this.allChecked = false;
      this.selects = [];
      this.ids = [];
      //计算滚动页数
      this.totalPage = this.tableData.length / this.pageSize;
      if (this.tableData.length % this.pageSize == 0) {
        this.totalPage = this.tableData.length / this.pageSize;
      } else {
        this.totalPage = parseInt(this.tableData.length / this.pageSize) + 1;
      }
      this.list = this.tableData.slice(0, this.currentPage * this.pageSize);
      this.lazyLoading();
    },
    lazyLoading() {
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this;
      dom.addEventListener("scroll", function () {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if (
              that.allChecked &&
              that.selects.length == that.tableData.length
            ) {
              that.$refs.tableScroll.toggleAllSelection();
              that.allChecked = true;
            } else if (that.selects.length != 0) {
              that.selects.map((v) => {
                that.list.map((item) => {
                  if (item.id == v.id) {
                    that.$nextTick(() => {
                      that.$refs.tableScroll.toggleRowSelection(item, true);
                    });
                  }
                });
              });
            }
          }
        }
      });
    },
    // 筛选打印
    getIds() {
      let ids = [];
      this.selects.map((v) => {
        ids.push(v.id);
      });
      this.ids = ids;
    },
    handleSelectionChange(val) {
      if (this.allChecked && this.list.length != this.tableData.length) {
        let currentIndex = 0;
        this.list.map((v, i2) => {
          val.map((e) => {
            if (e.id == v.id) {
              currentIndex = i2;
            }
          });
        });
        let arr = this.tableData.slice(currentIndex + 1, this.tableData.length);
        let newArr = [...val, ...arr];
        this.selects = newArr;
      } else {
        this.selects = val;
      }
      this.getIds();
    },
    handleSelectionChangeAll(e) {
      if (e.length != 0) {
        this.selects = this.tableData;
        this.allChecked = true;
        this.getIds();
      } else {
        this.selects = [];
        this.ids = [];
        this.allChecked = false;
      }
    },
    //筛选
    getType(flag) {
      this.listQuery.type = flag;
      this.getList();
    },
    refreshOne(row) {
      this.loading = true;
      cwtjFresh({ comId: row.id }).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.$qzfMessage(res.data.data);
          this.getList();
        }
      });
    },
    reFresh() {
      if (this.selects.length == 0) {
        this.$qzfMessage("请选择公司！", 1);
        return;
      }
      this.$confirm(
        "此操作会存在较大延时，建议使用单家数据更新！是否更新？",
        "提示",
        {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        let ids = this.selects.map(v => {
          return v.id
        })
        cwtjs({ comIds: ids }).then((res) => {
          this.loading = false;
          if (res.data.msg == "success") {
            this.$qzfMessage(res.data.data);
            this.getList();
          }
        });
      });
    },
    //导出
    daochu() {
      if (this.ids.length != 0) {
        this.listQuery.comIds = this.ids;
        this.listQuery.allFlag = 0;
        cwtjExport(this.listQuery).then((res) => {
          if (res.data.msg == "success") {
            window.open(res.data.data.url);
          }
        });
      } else {
        this.listQuery.comIds = [];
        this.$confirm("是否全部导出", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        })
          .then(() => {
            this.listQuery.allFlag = 1;
            cwtjExport(this.listQuery).then((res) => {
              if (res.data.msg == "success") {
                window.open(res.data.data.url);
              }
            });
          })
          .catch(() => {
            this.listQuery.allFlag = 0;
            cwtjExport(this.listQuery).then((res) => {
              if (res.data.msg == "success") {
                window.open(res.data.data.url);
              }
            });
          });
      }
    },
    //打印
    printAll() {
      if (this.ids.length != 0) {
        this.listQuery.comIds = this.ids;
        this.listQuery.allFlag = 0;
        // return
        cwtjPrint(this.listQuery).then((res) => {
          if (res.data.msg == "success") {
            window.open(res.data.data.url);
          }
        });
      } else {
        this.listQuery.comIds = [];
        this.$confirm("是否全部打印", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        })
          .then(() => {
            this.listQuery.allFlag = 1;
            // return
            cwtjPrint(this.listQuery).then((res) => {
              if (res.data.msg == "success") {
                window.open(res.data.data.url);
              }
            });
          })
          .catch(() => {
            this.listQuery.allFlag = 0;
            // return
            cwtjPrint(this.listQuery).then((res) => {
              if (res.data.msg == "success") {
                window.open(res.data.data.url);
              }
            });
          });
      }
    },
    // 表格排序
    sortChange(data) {
      if (data.column.label == "编号") {
        if (data.order == "descending") {
          this.listQuery.desc = 1;
        } else {
          this.listQuery.desc = 0;
        }
        this.getList();
      }
    },
    // 列表点击
    poper(row) {
      this.gridData = []
      companyUserList({ comId: row.id }).then((res) => {
        this.gridData = res.data.data.list;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.content_data {
  background-color: #d8ecff;
}

.content_data_size {
  display: flex;
  align-items: center;
  width: 98%;
  margin: 0 auto;
  height: 70px;
}

.each {
  width: 15%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
}

.each span {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.each a {
  text-decoration-line: underline;
  text-decoration-color: #141720;
}

.icon_bg {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #aedcfb;
  text-align: center;
  line-height: 35px;
}

.iconfont {
  font-size: 18px;
  color: #17a2b8;
}

.content_select {
  margin: 10px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hint {
  position: absolute;
  font-size: 12px;
  top: 12px;
  left: 120px;
  z-index: 2;
  color: #e6a23c;

  .icon-gantanhao1 {
    font-size: 11px;
    margin-right: 5px;
    color: #e6a23c;
  }
}
</style>
