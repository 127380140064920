<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-radio-group
          v-model="wbstatus"
          size="small"
          >
          <el-radio-button label="">普通科目余额表</el-radio-button>
          <el-radio-button label="wb">外币科目余额表</el-radio-button>
          <el-radio-button label="sl">数量金额科目余额表</el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <el-button type="primary" size="small" @click="openLastSub()">修改建账科目余额表</el-button>
        <el-button type="primary" size="small" @click="updateSubject">会计科目</el-button>
        <el-button type="primary" size="small" @click="editAll">批量修改</el-button>
      </div>
    </div>
    <div style="font-size:13px;margin-bottom: 10px;">
      {{ comName }}&nbsp;&nbsp;{{ listQuery.period }}&nbsp;&nbsp;账期期初数据
      <!-- {{comPeriod.slice(0,4)}}年&nbsp;{{comPeriod.slice(4,6)}}期&nbsp;&nbsp; -->

    </div>
    <el-table show-summary :summary-method="getSummaries" stripe :height="contentStyleObj" :data="showList" border  v-loading="loading" lazy :key="tableKey">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column label="科目编码" min-width="90" align="left" fixed show-overflow-tooltip>
        <template #default="scope">
          <span class="bianma" style="font-weight: 600" v-if="scope.row.parentId == 0">{{scope.row.subjectCode}}</span>
          <span class="bianma" style="padding-left:10px" v-else>{{scope.row.subjectCode}}</span>
        </template>
      </el-table-column>

      <el-table-column label="科目名称" min-width="120" align="left" fixed show-overflow-tooltip>
        <template #default="scope">
          <span style="font-weight: 600;text-underline-offset: 3px;" v-if="scope.row.parentId == 0">{{scope.row.subjectName}}</span>
          <span style="font-weight: 600;font-size:14px;color:var(--themeColor,#17a2b8)" v-else-if="!scope.row.subjectCode">{{ scope.row.subjectName }}</span>
          <span style="text-underline-offset: 3px;padding-left:10px" v-else>{{scope.row.subjectName}}</span>
        </template>
      </el-table-column>

      <el-table-column label="期初余额" align="center">
        <el-table-column  align="right" prop="periodBeginIn" label="借方" min-width="90" class="non_padding">
          <template #default="scope">
            <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-if="!scope.row.subjectCode">{{$comdify(scope.row.periodBeginIn)}}</span>
            <input v-if="scope.row.subjectCode"
              v-model="scope.row.periodBeginIn"
              style="border: 1px solid #b3aeae;width: 92%;height: 20px;line-height: 20px;color: #606266;outline: none;font-size: 12px;"
              placeholder="请输入金额" @change="changeBalancePi(scope.row)">
          </template>
        </el-table-column>
        <el-table-column  align="right" prop="periodBeginOut" label="贷方" min-width="90" class="non_padding">
          <template #default="scope">
            <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-if="!scope.row.subjectCode">{{$comdify(scope.row.periodBeginOut)}}</span>
            <input v-if="scope.row.subjectCode"
              v-model="scope.row.periodBeginOut"
              style="border: 1px solid #b3aeae;width: 92%;height: 20px;line-height: 20px;color: #606266;outline: none;font-size: 12px;"
              placeholder="请输入金额" @change="changeBalancePo(scope.row)">            
          </template>
        </el-table-column>

        <el-table-column v-if="wbstatus == 'wb'" align="right" prop="periodBeginInWb" label="外币借方" min-width="90" class="non_padding">
          <template #default="scope">
            <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-if="!scope.row.subjectCode">{{$comdify(scope.row.periodBeginInWb)}}</span>
            <input v-if="scope.row.subjectCode"
              v-model="scope.row.periodBeginInWb"
              style="border: 1px solid #b3aeae;width: 92%;height: 20px;line-height: 20px;color: #606266;outline: none;font-size: 12px;"
              placeholder="请输入金额" @change="changeBalancePiWb(scope.row)">
          </template>
        </el-table-column>
        <el-table-column v-if="wbstatus == 'wb'" align="right" prop="periodBeginOutWb" label="外币贷方" min-width="90" class="non_padding">
          <template #default="scope">
            <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-if="!scope.row.subjectCode">{{$comdify(scope.row.periodBeginOutWb)}}</span>
            <input v-if="scope.row.subjectCode"
              v-model="scope.row.periodBeginOutWb"
              style="border: 1px solid #b3aeae;width: 92%;height: 20px;line-height: 20px;color: #606266;outline: none;font-size: 12px;"
              placeholder="请输入金额"  @change="changeBalancePoWb(scope.row)">
          </template>
        </el-table-column>

        <el-table-column v-if="wbstatus == 'sl'" align="right" prop="beginCount" label="数量" min-width="90" class="non_padding">
          <template #default="scope">
            <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-if="!scope.row.subjectCode">{{$comdify(scope.row.beginCount)}}</span>
            <input v-if="scope.row.subjectCode"
              v-model="scope.row.beginCount"
              style="border: 1px solid #b3aeae;width: 92%;height: 20px;line-height: 20px;color: #606266;outline: none;font-size: 12px;"
              placeholder="请输入金额" @change="changeBalanceCount(scope.row)">
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="本期发生额" align="center" >
        <el-table-column align="right" prop="periodInt" label="借方" min-width="90" class="non_padding">
          <template #default="scope">
            <span v-if="scope.row.parentId == 0" style="font-weight:600">{{$comdify(scope.row.periodInt)}}</span>
            <span v-else-if="scope.row.parentId != 0 && scope.row.subjectCode">{{$comdify(scope.row.periodInt)}}</span>
            <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-else-if="!scope.row.subjectCode">{{$comdify(scope.row.periodInt)}}</span>
          </template>
        </el-table-column>  
        <el-table-column v-if="wbstatus == 'sl'" align="right" prop="inCount" label="数量借" min-width="90" class="non_padding">
          <template #default="scope">
            <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-if="!scope.row.subjectCode">{{this.comdify(scope.row.inCount)}}</span>
            <span v-else>{{this.comdify(scope.row.inCount)}}</span>
          </template>
        </el-table-column>
        <el-table-column align="right" prop="periodOut" label="贷方" min-width="90" class="non_padding">
          <template #default="scope">
            <span v-if="scope.row.parentId == 0" style="font-weight:600">{{$comdify(scope.row.periodOut)}}</span>
            <span v-else-if="scope.row.parentId != 0 && scope.row.subjectCode">{{$comdify(scope.row.periodOut)}}</span>
            <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-else-if="!scope.row.subjectCode">{{$comdify(scope.row.periodOut)}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="wbstatus == 'sl'" align="right" prop="outCount" label="数量贷" min-width="90" class="non_padding">
          <template #default="scope">
            <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-if="!scope.row.subjectCode">{{this.comdify(scope.row.outCount)}}</span>
            <span v-else>{{this.comdify(scope.row.outCount)}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="wbstatus == 'wb'" align="right" prop="periodIntWb" label="外币借方" min-width="90" class="non_padding">
          <template #default="scope">
            <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-if="!scope.row.subjectCode ">{{$comdify(scope.row.periodIntWb)}}</span>
            <span v-else>{{$comdify(scope.row.periodIntWb)}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="wbstatus == 'wb'" align="right" prop="periodOutWb" label="外币贷方" min-width="90" class="non_padding">
          <template #default="scope">
            <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-if="!scope.row.subjectCode">{{$comdify(scope.row.periodOutWb)}}</span>
            <span v-else>{{$comdify(scope.row.periodOutWb)}}</span>
          </template>
        </el-table-column>

       

       

      </el-table-column>

      <el-table-column label="本年累计发生额" align="center" v-if="yearAmount == false">
        <el-table-column align="right" prop="yearInt" label="借方" min-width="90" class="non_padding">
          <template #default="scope">
            <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-if="!scope.row.subjectCode">{{$comdify(scope.row.yearInt)}}</span>
            <input v-if="scope.row.subjectCode"
              v-model="scope.row.yearInt"
              style="border: 1px solid #b3aeae;width: 92%;height: 20px;line-height: 20px;color: #606266;outline: none;font-size: 12px;"
              placeholder="请输入金额" @change="changeBalanceYi(scope.row)">
          </template>
        </el-table-column>
        <el-table-column align="right" prop="yearOut" label="贷方" min-width="90" class="non_padding">
          <template #default="scope">
            <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-if="!scope.row.subjectCode">{{$comdify(scope.row.yearOut)}}</span>
            <input v-if="scope.row.subjectCode"
              v-model="scope.row.yearOut"
              style="border: 1px solid #b3aeae;width: 92%;height: 20px;line-height: 20px;color: #606266;outline: none;font-size: 12px;"
              placeholder="请输入金额" @change="changeBalanceYo(scope.row)">
          </template>
        </el-table-column>

        <el-table-column v-if="wbstatus == 'wb'" align="right" prop="yearIntWb" label="外币借方" min-width="90" class="non_padding">
          <template #default="scope">
            <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-if="!scope.row.subjectCode">{{$comdify(scope.row.yearIntWb)}}</span>
            <input v-if="scope.row.subjectCode"
              v-model="scope.row.yearIntWb"
              style="border: 1px solid #b3aeae;width: 92%;height: 20px;line-height: 20px;color: #606266;outline: none;font-size: 12px;"
              placeholder="请输入金额" @change="changeBalanceYiWb(scope.row)">
          </template>
        </el-table-column>
        <el-table-column v-if="wbstatus == 'wb'" align="right" prop="yearOutWb" label="外币贷方" min-width="90" class="non_padding">
          <template #default="scope">
            <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-if="!scope.row.subjectCode">{{$comdify(scope.row.yearOutWb)}}</span>
            <input v-if="scope.row.subjectCode"
              v-model="scope.row.yearOutWb"
              style="border: 1px solid #b3aeae;width: 92%;height: 20px;line-height: 20px;color: #606266;outline: none;font-size: 12px;"
              placeholder="请输入金额" @change="changeBalanceYoWb(scope.row)">
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="期末余额" align="center">
        <el-table-column align="right" prop="periodEndIn" label="借方" min-width="90" class="non_padding">
          <template #default="scope">
            <span v-if="scope.row.parentId == 0" style="font-weight:600">{{$comdify(scope.row.periodEndIn)}}</span>
            <span v-else-if="scope.row.parentId != 0 && scope.row.subjectCode">{{$comdify(scope.row.periodEndIn)}}</span>
            <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-else-if="!scope.row.subjectCode">{{$comdify(scope.row.periodEndIn)}}</span>
          </template>
        </el-table-column>
        <el-table-column align="right" prop="periodEndOut" label="贷方" min-width="90" class="non_padding">
          <template #default="scope">
            <span v-if="scope.row.parentId == 0" style="font-weight:600">{{$comdify(scope.row.periodEndOut)}}</span>
            <span v-else-if="scope.row.parentId != 0 && scope.row.subjectCode">{{$comdify(scope.row.periodEndOut)}}</span>
            <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-else-if="!scope.row.subjectCode">{{$comdify(scope.row.periodEndOut)}}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="wbstatus == 'wb'" align="right" prop="periodEndInWb" label="外币借方" min-width="90" class="non_padding">
          <template #default="scope">
            <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-if="!scope.row.subjectCode">{{$comdify(scope.row.periodEndInWb)}}</span>
            <span v-else>{{$comdify(scope.row.periodEndInWb)}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="wbstatus == 'wb'" align="right" prop="periodEndOutWb" label="外币贷方" min-width="90" class="non_padding">
          <template #default="scope">
            <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-if="!scope.row.subjectCode">{{$comdify(scope.row.periodEndOutWb)}}</span>
            <span v-else>{{$comdify(scope.row.periodEndOutWb)}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="wbstatus == 'sl'" align="right" prop="endCount" label="数量" min-width="90" class="non_padding">
          <template #default="scope">
            <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-if="!scope.row.subjectCode">{{this.comdify(scope.row.endCount)}}</span>
            <span v-else>{{this.comdify(scope.row.endCount)}}</span>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <div style="text-align: right;margin-top: 15px;">
      <el-button @click="saveSubject" type="primary" size="small">
        <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 14px;"></i>
        <span>保存</span>
      </el-button>
    </div>

    <el-drawer
      class="elDrawerSubject"
      :title="this.comName"
      v-model="drawer"
      :direction="direction"
      :before-close="handleClose"
      destroy-on-close
      size="80%"
    >
      <eaSubject ref="eaSubject" @success="init()"></eaSubject>
    </el-drawer>
  </div>
  <initEdit :comId="listQuery.comId" :period="listQuery.period" ref="initEdit" @success="getList"/>

  <el-dialog :close-on-click-modal="false"
    v-model="lastSubDialog"
    title="修改建账科目余额表"
    width="60%"
    destroy-on-close
  >
    <el-table show-summary :summary-method="getSummariesLast" stripe :height="contentStyleObj" :data="showListLast" border  v-loading="lastloading" lazy :key="tableKey">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column label="科目编码" min-width="90" align="left" fixed show-overflow-tooltip>
          <template #default="scope">
            <span class="bianma" style="font-weight: 600" v-if="scope.row.parentId == 0">{{scope.row.subjectCode}}</span>
            <span class="bianma" style="padding-left:10px" v-else>{{scope.row.subjectCode}}</span>
          </template>
        </el-table-column>

        <el-table-column label="科目名称" min-width="120" align="left" fixed show-overflow-tooltip>
          <template #default="scope">
            <span style="font-weight: 600;text-underline-offset: 3px;" v-if="scope.row.parentId == 0">{{scope.row.subjectName}}</span>
            <span style="font-weight: 600;font-size:14px;color:var(--themeColor,#17a2b8)" v-else-if="!scope.row.subjectCode">{{ scope.row.subjectName }}</span>
            <span style="text-underline-offset: 3px;padding-left:10px" v-else>{{scope.row.subjectName}}</span>
          </template>
        </el-table-column>

        <!-- <el-table-column label="期初余额" align="center">
          <el-table-column  align="right" prop="periodBeginIn" label="借方" min-width="90" class="non_padding">
            <template #default="scope">
              <span v-if="scope.row.parentId == 0" style="font-weight:600">{{$comdify(scope.row.periodBeginIn)}}</span>
            </template>
          </el-table-column>
          <el-table-column  align="right" prop="periodBeginOut" label="贷方" min-width="90" class="non_padding">
            <template #default="scope">
              <span v-if="scope.row.parentId == 0" style="font-weight:600">{{$comdify(scope.row.periodBeginOut)}}</span>
             
            </template>
          </el-table-column>
        </el-table-column> -->
        <el-table-column label="本期发生额" align="center" >
          <el-table-column align="right" prop="periodInt" label="借方" min-width="90" class="non_padding">
            <template #default="scope">
              <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-if="!scope.row.subjectCode">{{$comdify(scope.row.periodInt)}}</span>
              <input v-if="scope.row.subjectCode"
                v-model="scope.row.periodInt"
                style="border: 1px solid #b3aeae;width: 92%;height: 20px;line-height: 20px;color: #606266;outline: none;font-size: 12px;"
                placeholder="请输入金额" @change="changeBalancePiLast(scope.row)">
            </template>
          </el-table-column>  
         
          <el-table-column align="right" prop="periodOut" label="贷方" min-width="90" class="non_padding">
            <template #default="scope">
              <span style="color:var(--themeColor,#17a2b8);font-size:14px;font-weight:600;" v-if="!scope.row.subjectCode">{{$comdify(scope.row.periodOut)}}</span>
              <input v-if="scope.row.subjectCode"
                v-model="scope.row.periodOut"
                style="border: 1px solid #b3aeae;width: 92%;height: 20px;line-height: 20px;color: #606266;outline: none;font-size: 12px;"
                placeholder="请输入金额" @change="changeBalancePoLast(scope.row)">
            </template>
          </el-table-column>
        </el-table-column>

        <!-- <el-table-column label="本年累计发生额" align="center">
          <el-table-column align="right" prop="yearInt" label="借方" min-width="90" class="non_padding">
            <template #default="scope">
            <span v-if="scope.row.parentId == 0" style="font-weight:600">{{$comdify(scope.row.yearInt)}}</span>
          </template>

          </el-table-column>
          <el-table-column align="right" prop="yearOut" label="贷方" min-width="90" class="non_padding">
            <template #default="scope">
              <span v-if="scope.row.parentId == 0" style="font-weight:600">{{$comdify(scope.row.yearOut)}}</span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="期末余额" align="center">
          <el-table-column align="right" prop="periodEndIn" label="借方" min-width="90" class="non_padding">
            <template #default="scope">
              <span v-if="scope.row.parentId == 0" style="font-weight:600">{{$comdify(scope.row.periodEndIn)}}</span>
            </template>
          </el-table-column>
          <el-table-column align="right" prop="periodEndOut" label="贷方" min-width="90" class="non_padding">
            <template #default="scope">
              <span v-if="scope.row.parentId == 0" style="font-weight:600">{{$comdify(scope.row.periodEndOut)}}</span>
            </template>
          </el-table-column>
        </el-table-column> -->
      </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="lastSubDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="saveLastSub()">确认</el-button>
      </span>
    </template>
  </el-dialog>
  
</template>

<script>
import { subjectBalanceInitList,subjectBalanceSave,subjectBalanceInitLastList } from "@/api/subject";

import eaSubject from "../setting/subject.vue"
import initEdit from './components/initEdit.vue'
export default {
  name: "initSubject",
  components: {eaSubject,initEdit},
  props: {},
  data() {
    return {
      listQuery: {
        comId: null,
        period: "",
      },
      showList: [],
      contentStyleObj: {},
      comKj:"",
      comName:"",
      wbstatus:"",
      loading:false,
      yearAmount:false,
      drawer:false,
      comPeriod:"",
      oldList:[],
      tableKey:0,
      showListLast:[],
      lastSubDialog:false,
      lastloading:false,
    };
  },
  computed: {
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(230);
    this.listQuery.comId = this.$store.getters["commons/params"].cwcshComId * 1;
    this.comKj = this.$store.getters["commons/params"].cwcshComKj
    this.comName = this.$store.getters["commons/params"].cwcshComName
    let period = this.$store.getters["commons/params"].cwcshPeriod
    let year = period.slice(0,4)
    let month = period.slice(4,6)
    // if(month == '01'){
    //   year = year - 1
    //   month = '12'
    //   this.comPeriod = year +'' +month
    // }else{
    //   month = month - 1
    //   this.comPeriod = year +'' +month
    // }
    this.comPeriod = year +'' +month
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true
      subjectBalanceInitList({
        comId: this.listQuery.comId,
      }).then((res) => {
        this.loading = false
        if (res.data.msg == "success") {
          this.showList = res.data.data.list ? res.data.data.list : [];
          this.listQuery.period = res.data.data.period;
          this.assets = res.data.data.assest;
          this.liabilities = res.data.data.liabilities;
          this.interests = res.data.data.interests;
          this.cost = res.data.data.cost;
          this.profitAndLoss = res.data.data.profitAndLoss;
          //oldlist 赋值 --> 置空
          if(this.oldList.length != 0){
            this.showList.map(v=>{
              this.oldList.map(e=>{
                if(v.id == e.id){
                  v.periodBeginIn = e.periodBeginIn
                  v.periodBeginOut = e.periodBeginOut
                  v.periodInt = e.periodInt
                  v.periodOut = e.periodOut
                  v.yearInt = e.yearInt
                  v.yearOut = e.yearOut
                  v.periodEndIn = e.periodEndIn
                  v.periodEndOut = e.periodEndOut
                  v.periodBeginInWb = e.periodBeginInWb
                  v.periodBeginOutWb = e.periodBeginOutWb
                  v.periodIntWb = e.periodIntWb
                  v.periodOutWb = e.periodOutWb
                  v.yearIntWb = e.yearIntWb
                  v.yearOutWb = e.yearOutWb
                  v.beginCount = e.beginCount
                }
              })
            })
          }
          this.oldList = []
          let zcTotal = {
            subjectName: "资产合计",
            periodBeginIn: this.assets.periodBeginIn,
            periodBeginOut: this.assets.periodBeginOut,
            periodBeginInWb: this.assets.periodBeginInWb,
            periodBeginOutWb: this.assets.periodBeginOutWb,
            beginCount: this.assets.beginCount,
            periodInt: this.assets.periodInt,
            periodOut: this.assets.periodOut,
            periodIntWb: this.assets.periodIntWb,
            periodOutWb: this.assets.periodOutWb,
            inCount: this.assets.inCount,
            outCount: this.assets.outCount,
            yearInt: this.assets.yearInt,
            yearOut: this.assets.yearOut,
            yearIntWb: this.assets.yearIntWb,
            yearOutWb: this.assets.yearOutWb,
            periodEndIn: this.assets.periodEndIn,
            periodEndOut: this.assets.periodEndOut,
            periodEndInWb: this.assets.periodEndInWb,
            periodEndOutWb: this.assets.periodEndOutWb,
            endCount: this.assets.endCount,
          };
          let fzTotal = {
            subjectName: "负债合计",
            periodBeginIn: this.liabilities.periodBeginIn,
            periodBeginOut: this.liabilities.periodBeginOut,
            periodBeginInWb: this.liabilities.periodBeginInWb,
            periodBeginOutWb: this.liabilities.periodBeginOutWb,
            beginCount: this.liabilities.beginCount,
            periodInt: this.liabilities.periodInt,
            periodOut: this.liabilities.periodOut,
            periodIntWb: this.liabilities.periodIntWb,
            periodOutWb: this.liabilities.periodOutWb,
            inCount: this.liabilities.inCount,
            outCount: this.liabilities.outCount,
            yearInt: this.liabilities.yearInt,
            yearOut: this.liabilities.yearOut,
            yearIntWb: this.liabilities.yearIntWb,
            yearOutWb: this.liabilities.yearOutWb,
            periodEndIn: this.liabilities.periodEndIn,
            periodEndOut: this.liabilities.periodEndOut,
            periodEndInWb: this.liabilities.periodEndInWb,
            periodEndOutWb: this.liabilities.periodEndOutWb,
            endCount: this.liabilities.endCount,
          };
          let qyTotal = {
            subjectName: "权益合计",
            periodBeginIn: this.interests.periodBeginIn,
            periodBeginOut: this.interests.periodBeginOut,
            periodBeginInWb: this.interests.periodBeginInWb,
            periodBeginOutWb: this.interests.periodBeginOutWb,
            beginCount: this.interests.beginCount,
            periodInt: this.interests.periodInt,
            periodOut: this.interests.periodOut,
            periodIntWb: this.interests.periodIntWb,
            periodOutWb: this.interests.periodOutWb,
            inCount: this.interests.inCount,
            outCount: this.interests.outCount,
            yearInt: this.interests.yearInt,
            yearOut: this.interests.yearOut,
            yearIntWb: this.interests.yearIntWb,
            yearOutWb: this.interests.yearOutWb,
            periodEndIn: this.interests.periodEndIn,
            periodEndOut: this.interests.periodEndOut,
            periodEndInWb: this.interests.periodEndInWb,
            periodEndOutWb: this.interests.periodEndOutWb,
            endCount: this.interests.endCount,
          };
          let cbTotal = {
            subjectName: "成本合计",
            periodBeginIn: this.cost.periodBeginIn,
            periodBeginOut: this.cost.periodBeginOut,
            periodBeginInWb: this.cost.periodBeginInWb,
            periodBeginOutWb: this.cost.periodBeginOutWb,
            beginCount: this.cost.beginCount,
            periodInt: this.cost.periodInt,
            periodOut: this.cost.periodOut,
            periodIntWb: this.cost.periodIntWb,
            periodOutWb: this.cost.periodOutWb,
            inCount: this.cost.inCount,
            outCount: this.cost.outCount,
            yearInt: this.cost.yearInt,
            yearOut: this.cost.yearOut,
            yearIntWb: this.cost.yearIntWb,
            yearOutWb: this.cost.yearOutWb,
            periodEndIn: this.cost.periodEndIn,
            periodEndOut: this.cost.periodEndOut,
            periodEndInWb: this.cost.periodEndInWb,
            periodEndOutWb: this.cost.periodEndOutWb,
            endCount: this.cost.endCount,
          };
          let syTotal = {
            subjectName: "损益合计",
            periodBeginIn: this.profitAndLoss.periodBeginIn,
            periodBeginOut: this.profitAndLoss.periodBeginOut,
            periodBeginInWb: this.profitAndLoss.periodBeginInWb,
            periodBeginOutWb: this.profitAndLoss.periodBeginOutWb,
            beginCount: this.profitAndLoss.beginCount,
            periodInt: this.profitAndLoss.periodInt,
            periodOut: this.profitAndLoss.periodOut,
            periodIntWb: this.profitAndLoss.periodIntWb,
            periodOutWb: this.profitAndLoss.periodOutWb,
            inCount: this.profitAndLoss.inCount,
            outCount: this.profitAndLoss.outCount,
            yearInt: this.profitAndLoss.yearInt,
            yearOut: this.profitAndLoss.yearOut,
            yearIntWb: this.profitAndLoss.yearIntWb,
            yearOutWb: this.profitAndLoss.yearOutWb,
            periodEndIn: this.profitAndLoss.periodEndIn,
            periodEndOut: this.profitAndLoss.periodEndOut,
            periodEndInWb: this.profitAndLoss.periodEndInWb,
            periodEndOutWb: this.profitAndLoss.periodEndOutWb,
            endCount: this.profitAndLoss.endCount,
          };
          let zcTotalIndex = 0;
          let fzTotalIndex = 0;
          let qyTotalIndex = 0;
          let cbTotalIndex = 0;
          if (this.comKj == "民办非") {
            qyTotal.subjectName = "净资产合计";
            cbTotal.subjectName = "收入合计";
            syTotal.subjectName = "费用合计";
          }
          if(this.comKj != '民办非'){
          for (let i = 0; i < this.showList.length; i++) {
            if(this.showList[i].subjectCode == '2001' && this.showList[i].id != 0 ){
              zcTotalIndex = i;
            }else if(this.showList[i].subjectCode == '3001' && this.showList[i].id != 0){
              fzTotalIndex = i+1;
            }else if(this.showList[i].subjectCode == '4001' && this.showList[i].id != 0){
              qyTotalIndex = i+2;
            }else if(this.showList[i].subjectCode == '5001' && this.showList[i].id != 0){
              cbTotalIndex = i+3;
            }
          }
          this.showList.splice(zcTotalIndex,0,zcTotal)
          this.showList.splice(fzTotalIndex,0,fzTotal)
          this.showList.splice(qyTotalIndex,0,qyTotal)
          this.showList.splice(cbTotalIndex,0,cbTotal)
          this.showList.splice(this.showList.length,0,syTotal)
        }else if (this.comKj == '民办非'){
          for (let i = 0; i < this.showList.length; i++) {
            if(this.showList[i].subjectCode == '2101' && this.showList[i].id != 0 ){
              zcTotalIndex = i;
            }else if(this.showList[i].subjectCode == '3101' && this.showList[i].id != 0){
              fzTotalIndex = i+1;
            }else if(this.showList[i].subjectCode == '4101' && this.showList[i].id != 0){
              qyTotalIndex = i+2;
            }else if(this.showList[i].subjectCode == '5101' && this.showList[i].id != 0){
              cbTotalIndex = i+3;
            }
          }
          this.showList.splice(zcTotalIndex,0,zcTotal)
          this.showList.splice(fzTotalIndex,0,fzTotal)
          this.showList.splice(qyTotalIndex,0,qyTotal)
          this.showList.splice(cbTotalIndex,0,cbTotal)
          this.showList.splice(this.showList.length,0,syTotal)
        }
      
        }
      });

    },
     // 改变余额上级变
     changeBalancePi(row,p){
      let result= 0
      for (let i = 0; i < this.showList.length; i++) {
        if(this.showList[i].parentId == row.parentId && row.type == 1){
          result += (Number((this.showList[i].periodBeginIn+'').replace(/[,]/g, "")) - Number((this.showList[i].periodBeginOut+'').replace(/[,]/g, "")))
        }else if(this.showList[i].parentId == row.parentId && row.type == 2){
          result += (Number((this.showList[i].periodBeginOut+'').replace(/[,]/g, "")) - Number((this.showList[i].periodBeginIn+'').replace(/[,]/g, "")))
        }
      }
      this.showList.map(v => {
        if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 1){
          v.periodBeginIn = result.toFixed(2)
          this.changeBalancePi(v,row)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 1){
          v.periodBeginIn = result.toFixed(2)
        }else if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 2){
          v.periodBeginOut = result.toFixed(2)
          this.changeBalancePi(v,row)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 2){
          v.periodBeginOut = result.toFixed(2)
        }
      })
    },
    changeBalancePo(row){
      let result= 0
      for (let i = 0; i < this.showList.length; i++) {
        if(this.showList[i].parentId == row.parentId && row.type == 1){
          result += (Number((this.showList[i].periodBeginIn+'').replace(/[,]/g, "")) - Number((this.showList[i].periodBeginOut+'').replace(/[,]/g, "")))
        }else if(this.showList[i].parentId == row.parentId && row.type == 2){
          result += (Number((this.showList[i].periodBeginOut+'').replace(/[,]/g, "")) - Number((this.showList[i].periodBeginIn+'').replace(/[,]/g, "")))
        }
      }
      this.showList.map(v => {
        if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 1){
          v.periodBeginIn = result.toFixed(2)
          this.changeBalancePo(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 1){
          v.periodBeginIn = result.toFixed(2)
        }else if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 2){
          v.periodBeginOut = result.toFixed(2)
          this.changeBalancePo(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 2){
          v.periodBeginOut = result.toFixed(2)
        }
      })
    },
    changeBalanceYi(row){
      let result= 0
      for (let i = 0; i < this.showList.length; i++) {
        if(this.showList[i].parentId == row.parentId){
          result += Number((this.showList[i].yearInt+'').replace(/[,]/g, ""))
        }
      }
      this.showList.map(v => {
        if(v.subjectId == row.parentId && !v.remark && !row.remark){
          v.yearInt = result.toFixed(2)
          this.changeBalanceYi(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark){
          v.yearInt = result.toFixed(2)
        }
      })


      // let result= 0
      // for (let i = 0; i < this.showList.length; i++) {
      //   if(this.showList[i].parentId == row.parentId && row.type == 1){
      //     result += Number(this.showList[i].yearInt) - Number(this.showList[i].yearOut)
      //   }else if(this.showList[i].parentId == row.parentId && row.type == 2){
      //     result += Number(this.showList[i].yearOut)- Number(this.showList[i].yearInt)
      //   }
      // }
      // this.showList.map(v => {
      //   if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 1){
      //     v.yearInt = result.toFixed(2)
      //     this.changeBalanceYi(v)
      //   }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 1){
      //     v.yearInt = result.toFixed(2)
      //   }else if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 2){
      //     v.yearOut = result.toFixed(2)
      //     this.changeBalanceYi(v)
      //   }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 2){
      //     v.yearOut = result.toFixed(2)
      //   }
      // })
    },
    changeBalanceYo(row){

      let result= 0
      for (let i = 0; i < this.showList.length; i++) {
        if(this.showList[i].parentId == row.parentId){
          result += Number((this.showList[i].yearOut+'').replace(/[,]/g, ""))
        }
      }
      this.showList.map(v => {
        if(v.subjectId == row.parentId && !v.remark && !row.remark){
          v.yearOut = result.toFixed(2)
          this.changeBalanceYo(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark){
          v.yearOut = result.toFixed(2)
        }
      })


      // let result= 0
      // for (let i = 0; i < this.showList.length; i++) {
      //   if(this.showList[i].parentId == row.parentId && row.type == 1){
      //     result += Number(this.showList[i].yearInt) - Number(this.showList[i].yearOut)
      //   }else if(this.showList[i].parentId == row.parentId && row.type == 2){
      //     result += Number(this.showList[i].yearOut) - Number(this.showList[i].yearInt)
      //   }
      // }
      // this.showList.map(v => {
      //   if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 1){
      //     v.yearInt = result.toFixed(2)
      //     this.changeBalanceYo(v)
      //   }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 1){
      //     v.yearInt = result.toFixed(2)
      //   }else if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 2){
      //     v.yearOut = result.toFixed(2)
      //     this.changeBalanceYo(v)
      //   }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 2){
      //     v.yearOut = result.toFixed(2)
      //   }
      // })
    },
     // 外币的借贷方
    // 改变余额上级变
    changeBalancePiWb(row,p){
      let result= 0
      for (let i = 0; i < this.showList.length; i++) {
        if(this.showList[i].parentId == row.parentId && row.type == 1){
          result += Number((this.showList[i].periodBeginInWb+'').replace(/[,]/g, "")) - Number((this.showList[i].periodBeginOutWb+'').replace(/[,]/g, ""))
        }else if(this.showList[i].parentId == row.parentId && row.type == 2){
          result += Number((this.showList[i].periodBeginOutWb+'').replace(/[,]/g, "")) - Number((this.showList[i].periodBeginInWb+'').replace(/[,]/g, ""))
        }
      }
      this.showList.map(v => {
        if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 1){
          v.periodBeginInWb = result.toFixed(2)
          this.changeBalancePiWb(v,row)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 1){
          v.periodBeginInWb = result.toFixed(2)
        }else if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 2){
          v.periodBeginOutWb = result.toFixed(2)
          this.changeBalancePiWb(v,row)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 2){
          v.periodBeginOutWb = result.toFixed(2)
        }
      })
    },
    changeBalancePoWb(row){
      let result= 0
      for (let i = 0; i < this.showList.length; i++) {
        if(this.showList[i].parentId == row.parentId && row.type == 1){
          result += Number((this.showList[i].periodBeginInWb+'').replace(/[,]/g, "")) - Number((this.showList[i].periodBeginOutWb+'').replace(/[,]/g, ""))
        }else if(this.showList[i].parentId == row.parentId && row.type == 2){
          result += Number((this.showList[i].periodBeginOutWb+'').replace(/[,]/g, "")) - Number((this.showList[i].periodBeginInWb+'').replace(/[,]/g, ""))
        }
      }
      this.showList.map(v => {
        if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 1){
          v.periodBeginInWb = result.toFixed(2)
          this.changeBalancePoWb(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 1){
          v.periodBeginInWb = result.toFixed(2)
        }else if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 2){
          v.periodBeginOutWb = result.toFixed(2)
          this.changeBalancePoWb(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 2){
          v.periodBeginOutWb = result.toFixed(2)
        }
      })
    },
    changeBalanceYiWb(row){
      let result= 0
      for (let i = 0; i < this.showList.length; i++) {
        if(this.showList[i].parentId == row.parentId){
          result += Number((this.showList[i].yearIntWb+'').replace(/[,]/g, ""))
        }
      }
      this.showList.map(v => {
        if(v.subjectId == row.parentId && !v.remark && !row.remark){
          v.yearIntWb = result.toFixed(2)
          this.changeBalanceYiWb(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark){
          v.yearIntWb = result.toFixed(2)
        }
      })


      // let result= 0
      // for (let i = 0; i < this.showList.length; i++) {
      //   if(this.showList[i].parentId == row.parentId && row.type == 1){
      //     result += Number(this.showList[i].yearIntWb) - Number(this.showList[i].yearOutWb)
      //   }else if(this.showList[i].parentId == row.parentId && row.type == 2){
      //     result += Number(this.showList[i].yearOutWb) - Number(this.showList[i].yearIntWb)
      //   }
      // }
      // this.showList.map(v => {
      //   if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 1){
      //     v.yearIntWb = result.toFixed(2)
      //     this.changeBalanceYiWb(v)
      //   }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 1){
      //     v.yearIntWb = result.toFixed(2)
      //   }else if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 2){
      //     v.yearOutWb = result.toFixed(2)
      //     this.changeBalanceYiWb(v)
      //   }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 2){
      //     v.yearOutWb = result.toFixed(2)
      //   }
      // })
    },
    changeBalanceYoWb(row){
      let result= 0
      for (let i = 0; i < this.showList.length; i++) {
        if(this.showList[i].parentId == row.parentId){
          result += Number((this.showList[i].yearOutWb+'').replace(/[,]/g, ""))
        }
      }
      this.showList.map(v => {
        if(v.subjectId == row.parentId && !v.remark && !row.remark){
          v.yearOutWb = result.toFixed(2)
          this.changeBalanceYoWb(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark){
          v.yearOutWb = result.toFixed(2)
        }
      })


      // let result= 0
      // for (let i = 0; i < this.showList.length; i++) { 
      //   if(this.showList[i].parentId == row.parentId && row.type == 1){
      //     result += Number(this.showList[i].yearIntWb) - Number(this.showList[i].yearOutWb)
      //   }else if(this.showList[i].parentId == row.parentId && row.type == 2){
      //     result += Number(this.showList[i].yearOutWb) - Number(this.showList[i].yearIntWb)
      //   }
      // }
      // this.showList.map(v => {
      //   if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 1){
      //     v.yearIntWb = result.toFixed(2)
      //     this.changeBalanceYoWb(v)
      //   }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 1){
      //     v.yearIntWb = result.toFixed(2)
      //   }else if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 2){
      //     v.yearOutWb = result.toFixed(2)
      //     this.changeBalanceYoWb(v)
      //   }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 2){
      //     v.yearOutWb = result.toFixed(2)
      //   }
      // })
    },
     // 数量的计算
     changeBalanceCount(row){
      let result= 0
      for (let i = 0; i < this.showList.length; i++) {
        if(this.showList[i].parentId == row.parentId){
          result += Number((this.showList[i].beginCount+'').replace(/[,]/g, ""))
        }
      }
      this.showList.map(v => {
        if(v.subjectId == row.parentId && !v.remark && !row.remark){
          v.beginCount = result.toFixed(2)
          this.changeBalanceCount(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark){
          v.beginCount = result.toFixed(2)
        }
      })
    },
    saveSubject() {
      this.loading = true
      this.showList.map(v=>{
        v.periodBeginIn = Number((v.periodBeginIn+'').replace(/[,]/g, ""))
        v.periodBeginOut = Number((v.periodBeginOut+'').replace(/[,]/g, ""))
        v.periodInt = Number((v.periodInt+'').replace(/[,]/g, ""))
        v.periodOut = Number((v.periodOut+'').replace(/[,]/g, ""))
        v.yearInt = Number((v.yearInt+'').replace(/[,]/g, ""))
        v.yearOut = Number((v.yearOut+'').replace(/[,]/g, ""))
        v.periodEndIn = Number((v.periodEndIn+'').replace(/[,]/g, ""))
        v.periodEndOut = Number((v.periodEndOut+'').replace(/[,]/g, ""))
        v.periodBeginInWb = Number((v.periodBeginInWb+'').replace(/[,]/g, ""))
        v.periodBeginOutWb = Number((v.periodBeginOutWb+'').replace(/[,]/g, ""))
        v.periodIntWb = Number((v.periodIntWb+'').replace(/[,]/g, ""))
        v.periodOutWb = Number((v.periodOutWb+'').replace(/[,]/g, ""))
        v.yearIntWb = Number((v.yearIntWb+'').replace(/[,]/g, ""))
        v.yearOutWb = Number((v.yearOutWb+'').replace(/[,]/g, ""))
        v.beginCount = Number((v.beginCount+'').replace(/[,]/g, ""))
      })
      let fzhsList = []
      this.showList.map(v => {
        if(v.remark == '辅助核算'){
          fzhsList.push(v)
        }
      })
      let str = "" 
      let param = this.showList;
      for (var i = 0; i < param.length; i++) {
        if(this.comKj == '民办非'){
          if ((param[i].subjectName == '资产合计' || param[i].subjectName == '负债合计' || param[i].subjectName == '净资产合计' || param[i].subjectName == '收入合计'|| param[i].subjectName == '费用合计' || param[i].remark == '辅助核算') && !param[i].id) {
          param.splice(i, 1);
          i--;
        }
        }else{
          if ((param[i].subjectName == '资产合计' || param[i].subjectName == '负债合计' || param[i].subjectName == '权益合计' || param[i].subjectName == '成本合计'|| param[i].subjectName == '损益合计' || param[i].remark == '辅助核算') && !param[i].id) {
          param.splice(i, 1);
          i--;
        }
        }
      
      }
      param.map(v=>{
        let codeStr = this.findDirectSubordinateAmount(v, param);
        if (codeStr != "") {
          str = codeStr
        }
      })
      if(str != ""){
        this.loading = false
        this.$message.error(str + "下级余额不等于此科目");
        return
      }
      // 保存除去辅助核算
      let saveList = []
      this.showList.map(v => {
        if(!v.remark){
          saveList.push(v)
        }
      })
      subjectBalanceSave({
        list:saveList,
        fzhsList:fzhsList,
        period:this.listQuery.period,
        comId:this.listQuery.comId
        }).then(res => {
          this.loading = false
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功", 3)
          this.getList()
        }
      })
    },
     // 科目限制
     findDirectSubordinateAmount(codeBalance,subjects){
      let code = codeBalance.subjectCode
      let codeLen = this.SubjectLens(code)
      let status = false // 是否存在下级
      let amountBeginIn = 0.0
      let amountBeginOut = 0.0
      let amountYearIn = 0.0
      let amountYearOut = 0.0
      subjects.map(subject=>{
        if(subject.subjectCode.length > code.length && (codeLen+1) == this.SubjectLens(subject.subjectCode) && code + "." == subject.subjectCode.slice(0,code.length + 1)){
          status = true
          amountBeginIn = (amountBeginIn*1 + subject.periodBeginIn*1).toFixed(2)
          amountBeginOut = (amountBeginOut*1 + subject.periodBeginOut*1).toFixed(2)
          amountYearIn = (amountYearIn*1 + subject.yearInt*1).toFixed(2)
          amountYearOut = (amountYearOut*1 + subject.yearOut*1).toFixed(2)
        }
      })
      if (status) {
        if (codeBalance.periodBeginIn != 0) {
          amountBeginIn = (amountBeginIn*1 - amountBeginOut*1).toFixed(2)  
          amountBeginOut = 0
        } else {
          amountBeginOut = (amountBeginOut*1 - amountBeginIn*1).toFixed(2)
          amountBeginIn = 0
        }
        if (codeBalance.periodBeginIn != amountBeginIn || codeBalance.periodBeginOut != amountBeginOut || codeBalance.yearInt != amountYearIn || codeBalance.yearOut != amountYearOut) {
          return codeBalance.subjectCode
        }
      }
      return ""
    },
    SubjectLens(code){
      return code.split('.').length
    },
    getSummaries(param){
      let { columns, data } = param;
      let periodBeginIn = 0
      let periodBeginOut = 0
      let periodInt = 0
      let periodOut = 0
      let yearInt = 0
      let yearOut = 0

      let periodBeginInWb = 0
      let periodBeginOutWb = 0
      let periodIntWb = 0
      let periodOutWb = 0
      let yearIntWb = 0
      let yearOutWb = 0

      let periodEndIn = 0
      let periodEndOut = 0
      let periodEndInWb = 0
      let periodEndOutWb = 0
      data = this.showList
      data.map(v=>{
        if(this.comKj == '民办非'){
          if((v.subjectName != '资产合计' && v.subjectName != '负债合计'  && v.subjectName != '净资产合计' && v.subjectName != '收入合计' && v.subjectName != '费用合计') && v.subjectCode.length == 4 && v.remark != '辅助核算' ){
            if(v.periodEndIn != 0){
            }
            periodBeginIn +=  Number((v.periodBeginIn+'').replace(/[,]/g, ""))
            periodBeginOut += Number((v.periodBeginOut+'').replace(/[,]/g, ""))
            periodInt += Number((v.periodInt+'').replace(/[,]/g, ""))
            periodOut += Number((v.periodOut+'').replace(/[,]/g, ""))
            periodEndIn += Number((v.periodEndIn+'').replace(/[,]/g, ""))
            periodEndOut += Number((v.periodEndOut+'').replace(/[,]/g, ""))
            yearInt += Number((v.yearInt+'').replace(/[,]/g, ""))
            yearOut += Number((v.yearOut+'').replace(/[,]/g, ""))

            periodBeginInWb += Number((v.periodBeginInWb+'').replace(/[,]/g, ""))
            periodBeginOutWb += Number((v.periodBeginOutWb+'').replace(/[,]/g, ""))
            periodIntWb += Number((v.periodIntWb+'').replace(/[,]/g, ""))
            periodOutWb += Number((v.periodOutWb+'').replace(/[,]/g, ""))
            yearIntWb += Number((v.yearIntWb+'').replace(/[,]/g, ""))
            yearOutWb += Number((v.yearOutWb+'').replace(/[,]/g, ""))
            periodEndInWb += Number((v.periodEndInWb+'').replace(/[,]/g, ""))
            periodEndOutWb += Number((v.periodEndOutWb+'').replace(/[,]/g, ""))
          }
        }else{
          if((v.subjectName != '资产合计' && v.subjectName != '负债合计'  && v.subjectName != '权益合计' && v.subjectName != '成本合计' && v.subjectName != '损益合计') && v.subjectCode.length == 4 && v.remark != '辅助核算' ){
          if(v.periodEndIn != 0){
          }
          periodBeginIn +=  Number((v.periodBeginIn+'').replace(/[,]/g, ""))
          periodBeginOut += Number((v.periodBeginOut+'').replace(/[,]/g, ""))
          periodInt += Number((v.periodInt+'').replace(/[,]/g, ""))
          periodOut += Number((v.periodOut+'').replace(/[,]/g, ""))
          periodEndIn += Number((v.periodEndIn+'').replace(/[,]/g, ""))
          periodEndOut += Number((v.periodEndOut+'').replace(/[,]/g, ""))
          yearInt += Number((v.yearInt+'').replace(/[,]/g, ""))
          yearOut += Number((v.yearOut+'').replace(/[,]/g, ""))

          periodBeginInWb += Number((v.periodBeginInWb+'').replace(/[,]/g, ""))
          periodBeginOutWb += Number((v.periodBeginOutWb+'').replace(/[,]/g, ""))
          periodIntWb += Number((v.periodIntWb+'').replace(/[,]/g, ""))
          periodOutWb += Number((v.periodOutWb+'').replace(/[,]/g, ""))
          yearIntWb += Number((v.yearIntWb+'').replace(/[,]/g, ""))
          yearOutWb += Number((v.yearOutWb+'').replace(/[,]/g, ""))
          periodEndInWb += Number((v.periodEndInWb+'').replace(/[,]/g, ""))
          periodEndOutWb += Number((v.periodEndOutWb+'').replace(/[,]/g, ""))
        }
        } 
      })
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }if(column.property == "periodBeginIn"){
          sums[index] = this.$comdify(periodBeginIn.toFixed(2))
        }else if(column.property == "periodBeginOut"){
          sums[index] = this.$comdify(periodBeginOut.toFixed(2))
        }else if(column.property == "periodInt"){
          sums[index] = this.$comdify(periodInt.toFixed(2))
        }else if(column.property == "periodOut"){
          sums[index] = this.$comdify(periodOut.toFixed(2))
        }else if(column.property == "yearInt"){
          sums[index] = this.$comdify(yearInt.toFixed(2))
        }else if(column.property == "yearOut"){
          sums[index] = this.$comdify(yearOut.toFixed(2))
        }else if(column.property == "periodBeginInWb"){
          sums[index] = this.$comdify(periodBeginInWb.toFixed(2))
        }else if(column.property == "periodBeginOutWb"){
          sums[index] = this.$comdify(periodBeginOutWb.toFixed(2))
        }else if(column.property == "periodIntWb"){
          sums[index] = this.$comdify(periodIntWb.toFixed(2))
        }else if(column.property == "periodOutWb"){
          sums[index] = this.$comdify(periodOutWb.toFixed(2))
        }else if(column.property == "yearIntWb"){
          sums[index] = this.$comdify(yearIntWb.toFixed(2))
        }else if(column.property == "yearOutWb"){
          sums[index] = this.$comdify(yearOutWb.toFixed(2))
        }else if(column.property == "periodEndIn"){
          sums[index] = this.$comdify(periodEndIn.toFixed(2))
        }else if(column.property == "periodEndOut"){
          sums[index] = this.$comdify(periodEndOut.toFixed(2))
        }else if(column.property == "periodEndInWb"){
          sums[index] = this.$comdify(periodEndInWb.toFixed(2))
        }else if(column.property == "periodEndOutWb"){
          sums[index] = this.$comdify(periodEndOutWb.toFixed(2))
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
    // 数量的小数位
    comdify(n) {
      if(!n) return n;
      n = n.toString()
      let str = n.split('.');
      let re = /\d{1,3}(?=(\d{3})+$)/g;
      let n1 = str[0].replace(re, "$&,");
      return str.length > 1 && str[1] ? `${n1}.${str[1]}` : `${n1}.00`;
    },

    // 修改会计科目
    updateSubject(){
      this.drawer = true
      this.$nextTick(function () {
        this.$refs.eaSubject.getList("",this.listQuery.comId,"start")
      })
      
      // this.$refs.eaSubject.getList("",this.listQuery.comId)
    },
    init(){
      this.oldList = this.showList
      this.getList()
    },
    editAll(){
      this.$refs.initEdit.init()
    },
    getLastSubList(){
      subjectBalanceInitLastList({
        comId: this.listQuery.comId,
      }).then((res) => {
        this.lastloading = false
        if (res.data.msg == "success") {
          this.showListLast = res.data.data.list ? res.data.data.list : [];
        }
      });
    },
    openLastSub(){
      this.lastloading = true
      this.getLastSubList()
      // subjectBalanceInitLastList({
      //   comId: this.listQuery.comId,
      // }).then((res) => {
      //   this.loading = false
      //   if (res.data.msg == "success") {
      //     this.showListLast = res.data.data.list ? res.data.data.list : [];
      //   }
      // });
      this.lastSubDialog = true
    },
    saveLastSub(){
      this.lastloading = true
      this.showListLast.map(v=>{
        v.periodBeginIn = Number((v.periodBeginIn+'').replace(/[,]/g, ""))
        v.periodBeginOut = Number((v.periodBeginOut+'').replace(/[,]/g, ""))
        v.periodInt = Number((v.periodInt+'').replace(/[,]/g, ""))
        v.periodOut = Number((v.periodOut+'').replace(/[,]/g, ""))
        v.yearInt = Number((v.yearInt+'').replace(/[,]/g, ""))
        v.yearOut = Number((v.yearOut+'').replace(/[,]/g, ""))
        v.periodEndIn = Number((v.periodEndIn+'').replace(/[,]/g, ""))
        v.periodEndOut = Number((v.periodEndOut+'').replace(/[,]/g, ""))
      })
      let str = "" 
      let param = this.showListLast;
      param.map(v=>{
        let codeStr = this.findDirectSubordinateAmount(v, param);
        if (codeStr != "") {
          str = codeStr
        }
      })
      if(str != ""){
        this.lastloading = false
        this.$message.error(str + "下级余额不等于此科目");
        return
      }
      subjectBalanceSave({
        list:this.showListLast,
        period:this.listQuery.period,
        comId:this.listQuery.comId,
        yzStatus:1,
        }).then(res => {
          this.lastloading = false
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功,请关闭弹窗手动修改初始账期余额表", 3)
          this.getLastSubList()
          this.getList()
        }
      })
    },

    changeBalancePiLast(row,p){
      let result= 0
      for (let i = 0; i < this.showListLast.length; i++) {
        if(this.showListLast[i].parentId == row.parentId && row.type == 1){
          result += (Number((this.showListLast[i].periodInt+'').replace(/[,]/g, "")) - Number((this.showListLast[i].periodOut+'').replace(/[,]/g, "")))
        }else if(this.showListLast[i].parentId == row.parentId && row.type == 2){
          result += (Number((this.showListLast[i].periodOut+'').replace(/[,]/g, "")) - Number((this.showListLast[i].periodInt+'').replace(/[,]/g, "")))
        }
      }
      this.showListLast.map(v => {
        if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 1){
          v.periodInt = result.toFixed(2)
          this.changeBalancePiLast(v,row)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 1){
          v.periodInt = result.toFixed(2)
        }else if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 2){
          v.periodOut = result.toFixed(2)
          this.changeBalancePiLast(v,row)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 2){
          v.periodOut = result.toFixed(2)
        }
      })
    },
    changeBalancePoLast(row){
      let result= 0
      for (let i = 0; i < this.showListLast.length; i++) {
        if(this.showListLast[i].parentId == row.parentId && row.type == 1){
          result += (Number((this.showListLast[i].periodInt+'').replace(/[,]/g, "")) - Number((this.showListLast[i].periodOut+'').replace(/[,]/g, "")))
        }else if(this.showListLast[i].parentId == row.parentId && row.type == 2){
          result += (Number((this.showListLast[i].periodOut+'').replace(/[,]/g, "")) - Number((this.showListLast[i].periodInt+'').replace(/[,]/g, "")))
        }
      }
      this.showListLast.map(v => {
        if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 1){
          v.periodInt = result.toFixed(2)
          this.changeBalancePoLast(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 1){
          v.periodInt = result.toFixed(2)
        }else if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 2){
          v.periodOut = result.toFixed(2)
          this.changeBalancePoLast(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 2){
          v.periodOut = result.toFixed(2)
        }
      })
    },
    getSummariesLast(param){
      let { columns, data } = param;
      let periodBeginIn = 0
      let periodBeginOut = 0
      let periodInt = 0
      let periodOut = 0
      let yearInt = 0
      let yearOut = 0
      let periodEndIn = 0
      let periodEndOut = 0
      data = this.showListLast
      data.map(v=>{
        if (v.subjectCode.length == 4){
          if(v.periodEndIn != 0){
          }
          periodBeginIn +=  Number((v.periodBeginIn+'').replace(/[,]/g, ""))
          periodBeginOut += Number((v.periodBeginOut+'').replace(/[,]/g, ""))
          periodInt += Number((v.periodInt+'').replace(/[,]/g, ""))
          periodOut += Number((v.periodOut+'').replace(/[,]/g, ""))
          periodEndIn += Number((v.periodEndIn+'').replace(/[,]/g, ""))
          periodEndOut += Number((v.periodEndOut+'').replace(/[,]/g, ""))
          yearInt += Number((v.yearInt+'').replace(/[,]/g, ""))
          yearOut += Number((v.yearOut+'').replace(/[,]/g, ""))
        }
        
      })
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }if(column.property == "periodBeginIn"){
          sums[index] = this.$comdify(periodBeginIn.toFixed(2))
        }else if(column.property == "periodBeginOut"){
          sums[index] = this.$comdify(periodBeginOut.toFixed(2))
        }else if(column.property == "periodInt"){
          sums[index] = this.$comdify(periodInt.toFixed(2))
        }else if(column.property == "periodOut"){
          sums[index] = this.$comdify(periodOut.toFixed(2))
        }else if(column.property == "yearInt"){
          sums[index] = this.$comdify(yearInt.toFixed(2))
        }else if(column.property == "yearOut"){
          sums[index] = this.$comdify(yearOut.toFixed(2))
        }else if(column.property == "periodEndIn"){
          sums[index] = this.$comdify(periodEndIn.toFixed(2))
        }else if(column.property == "periodEndOut"){
          sums[index] = this.$comdify(periodEndOut.toFixed(2))
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
  },
};
</script>

<style lang="scss" scoped>
.non_padding{
    padding:0;
}
.non_padding .cell{
    padding:0
}
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
span{
  font-size: 12px;
}
</style>
<style lang="scss">
.el-table__header,.el-table__body,.el-table__footer{
   width: 100%;
   table-layout: fixed !important;
}
</style>
